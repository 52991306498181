<template>
  <v-sheet
    class="px-4 py-4 mr-5 my-4 d-print-none"
    color="amber lighten-4"
    style="margin-left:140px"
    width="100%"
  >
    <div class="text-h5 pb-2 px-1 brown--text text--darken-2">{{ dictionary['acbCA'].longname }}</div>

    <v-layout column>
      <TextArea
        auto-grow
        class="mb-2"
        field="acbCA"
        height="auto"
        :value="text"
        :placeholder="`Enter ${dictionary['acbCA'].longname} here ...`"
        @change="change"
      />
      <v-layout align-center>
        <v-layout align-center space-between class="brown--text text--darken-4">
          <div class="mr-2 px-1">
            {{ dictionary['acbCompDate'].longname }} :
          </div>
          <div>
            <DatePicker
              field="acbCompDate"
              :allowed-dates="allowedDates"
              :value="date"
              @change="change"
            />
          </div>
        </v-layout>
        <v-btn
          depressed
          color="primary"
          :loading="saving"
          :disabled="!(date && text)"
          @click="update"
        >
          Update
        </v-btn>
      </v-layout>
    </v-layout>
  </v-sheet>
</template>

<script>
import { dictionary } from '@/models/finding'

import DatePicker from '@/components/UI/date-picker'
import TextArea from '@/components/UI/forms/textarea'

export default {
  components: {
    DatePicker,
    TextArea
  },
  props: {
    finding: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      dictionary,
      date: null,
      text: null,
      saving: false
    }
  },
  methods: {
    allowedDates(date) {
      const a = 8.64e7  // 1 days in milliseconds
      const b = a * 30  // 30 days in milliseconds
      const d = new Date(date).valueOf()
      const e = this.finding.acbDate.valueOf()
      const today = new Date(new Date().toDateString()).valueOf()
      return (d > (today - b)) && (d <= today + a) && (d >= e)
    },
    change({ field, value}) {
      if (field === 'acbCA'){
        this.text = value
      }
      if (field === 'acbCompDate'){
        this.date = value
      }
    },
    update() {
      this.saving = true
      const upd = {
        acbCA: this.text,
        acbCompDate: this.date
      }
      this.finding.patch({ data: upd }).then(() => {
        setTimeout(() => {
          this.saving = false
          this.$snotify.success('Details saved', 'Success!')
        }, 1000)
      })
    }
  },
  watch: {
    finding: {
      handler (newVal){
        this.text = newVal.acbCA
        this.date = newVal.acbCompDate
         ? new Date(newVal.acbCompDate).toISOString().substr(0, 10)
         : null
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
